.experience-container {
  display: flex;
  align-items: center;
}

/* .experience-container > div {
  background: var(--color-bg-variant);
  padding: 2rem 5rem;
  border-radius: 2rem;
  border: 1px solid transparent;
  width: 70%;
  transition: var(--transition);
} */

.experience-container > div:hover {
  background: transparent;
  border-color: var(--color-bg-variant);
  cursor: default;
}

.experience-container > div h3 {
  text-align: center;
  margin-bottom: 2rem;
  color: var(--color-primary);
}

.experience-content {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 2rem;
  margin: auto;
  width: 70%;
}

.experience-details {
  display: flex;
  gap: 1rem;
  background: var(--color-bg-variant);
  padding: 1rem;
  border: 1px solid transparent;
  transition: var(--transition);
}

.experience-details .icon {
  margin-top: 6px;
  color: var(--color-primary);
}

/*=============For Medium Devices=============*/

@media screen and (max-width: 1024px) {
  .experience-container {
    grid-template-columns: 1fr;
  }
  .experience-container > div {
    width: 90%;
    padding: 2rem;
    margin: 0 auto;
  }
  .experience-content {
    padding: 1rem;
  }
}

/*=============For Small Devices=============*/
@media screen and (max-width: 600px) {
  .experience-container {
    gap: 1rem;
  }
  .experience-container > div {
    padding: 1rem;
  }
}
